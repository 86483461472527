import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import PreviewPanelNode from './PreviewPanelNode';
import {UilRefresh} from '@iconscout/react-unicons';
import { MAIN_TYPES } from '../../data';

import './PreviewPanel.scss';

const ActionOrBubbleMap = {
  'BUTTON_INPUT' : 'ACTION',
  'EMAIL_INPUT' : 'ACTION',
  'PHONE_INPUT' : 'ACTION',
  'TEXT_INPUT' : 'ACTION',
  'TEXT_BUBBLE' : 'BUBBLE'
};

const PreviewPanel = ({flows}) => {

  const [selectedButtonNodes, setSelectedButtonNodes] = useState([]);
  const [parentButtonNodes, setParentButtonNodes] = useState([]);
  const [submittedUserInputs, setSubmittedUserInputs] = useState([]);
  const [previewBotFlows, setPreviewBotFlows] = useState([]);
  const [passedNodes, setPassedNodes] = useState([]);

const getNextNode = useCallback((currentNodeId)=> {
    const currentNode = flows.find((node) => node.nodeId === currentNodeId);

    if (!currentNode) {
      console.error('Current node not found!');
      return null;
    }
  
    if(currentNode?.targetNodeIds?.length > 0){
      const nextNodeId =  currentNode.targetNodeIds[0];
      const nextNode = flows.find((node) => node.nodeId === nextNodeId);
      return nextNode;
    }

},[flows]);


const previewFlow = useCallback(() =>{

  let currentNode = flows.find((node) => node.sourceNodeId === '');
  let botFlows = [];

  while (currentNode) {

    botFlows.push(currentNode);

    if (ActionOrBubbleMap[currentNode.type] === MAIN_TYPES.ACTION) {

      //eslint-disable-next-line
       const subNodes = flows.filter((item)=> item.parentNode === currentNode?.nodeId);
       const intersection = subNodes.filter(subNode => {
          return passedNodes.includes(subNode.nodeId);
        });

      const currentId = intersection.length > 0 ? intersection[0]?.nodeId : currentNode.nodeId

      if(passedNodes.length > 0 && passedNodes.includes(currentId)){
          currentNode = getNextNode(currentId);
      }else break;
      
    }else{
        //eslint-disable-next-line
        currentNode = flows.find((node) => node.nodeId === currentNode.targetNodeIds[0]);
    }
     
  }

  botFlows.splice(0, 1)
  setPreviewBotFlows(botFlows);
  
},[
  flows, 
  getNextNode, 
  setPreviewBotFlows,
  passedNodes
]);

const refreshPanel = useCallback(()=>{
  setPreviewBotFlows([]);
  setSelectedButtonNodes([]);
  setParentButtonNodes([]);
  setSubmittedUserInputs([]);
  setPassedNodes([]);
  previewFlow();
},[
  setPreviewBotFlows, 
  previewFlow, 
  setSelectedButtonNodes,
  setParentButtonNodes,
  setSubmittedUserInputs,
  setPassedNodes
]);

useEffect(()=>{
  previewFlow();
  //eslint-disable-next-line
},[flows]);

  return (
    <Card 
      className='preview-panel py-1 px-2' 
      style={{ width: '26rem' }}
    >
    <Card.Body>
      <div className='refresh-button d-flex align-items-end'>
          <Button
            variant='outline-primary'
            id="button-addon2"
            onClick={refreshPanel}
            size="sm"
          >
            <UilRefresh size="15"/>
            Refresh
          </Button>
      </div>
      <div className="chatbot-widget">
          <div className="chatbot-messages">
          {previewBotFlows?.length > 0 && previewBotFlows.map((node, index)=> (
            <>
            {node &&
            <PreviewPanelNode 
            node={node} 
            key={index} 
            nodes={flows} 
            selectedButtonNodes={selectedButtonNodes}
            setSelectedButtonNodes={setSelectedButtonNodes}
            setParentButtonNodes={setParentButtonNodes}
            parentButtonNodes={parentButtonNodes}
            setSubmittedUserInputs={setSubmittedUserInputs}
            submittedUserInputs={submittedUserInputs}
            setPassedNodes={setPassedNodes}
            passedNodes={passedNodes}

          /> 
            }
          </> 
          ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default PreviewPanel;