import React from 'react';
import { useLocation } from 'react-router-dom';
import FlowEditorContainer from '../flowEditor/FlowEditorContainer';

const FlowView = () => {

  const location = useLocation();
  const data = location.state;
  return (
    <FlowEditorContainer flowDetails={data}/>
  )
}

export default FlowView;
