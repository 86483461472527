export const renderLinksFromString = (value) => {
    if (!value) {
        return null;
      }    
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const textParts = value.split(linkRegex);
  
    return textParts.map((part, index) => {
      if (part.match(linkRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };
  