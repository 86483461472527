import React from 'react';

const ErrorBoundary = ({ error }) => {
  return (
    <div>
      <h2>Oops, something went wrong!</h2>
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default ErrorBoundary;
