import React, {useCallback} from 'react';
import {Modal, Button} from 'react-bootstrap';
import { toast } from "react-toastify";
import {deleteFlowBot} from '../../../Services'

const DeleteConfirmationModal = ({show, setShow,botId, loadBotFlows}) => {

    const handleClose = useCallback(() => {
        setShow(false);
    },[setShow]);

    const onDeleteBotFlow = useCallback(async()=> {
        try{
            await deleteFlowBot(botId)
            toast.success(
                'Successfully deleted a flow bot!'
              );
            handleClose();
            loadBotFlows();

        }catch(e){
            console.error(`Failed to delete a flow bot`);
            toast.error(
                'Failed to delete the flow bot!'
              );
        }

    },[
        botId,
        handleClose,
        loadBotFlows
    ]);
  return (
    <Modal 
        show={show} 
        onHide={handleClose}
        centered
        className='flow-modal'
    >
        <Modal.Header 
            className='py-4'
            closeButton>
            <Modal.Title>
                Delete Flow Bot
            </Modal.Title>
        </Modal.Header>
        <Modal.Body  className='py-4'>
            <p>Do you want to delete this flow bot ?</p>
        </Modal.Body>
        <Modal.Footer  className='pt-4'>
          <Button 
            variant="outline-secondary" 
            onClick={handleClose}
            >
            No, Cancel
          </Button>
          <Button 
            variant="outline-danger" 
            onClick={onDeleteBotFlow}
            >
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default DeleteConfirmationModal