import React, {useCallback, useMemo} from 'react';
import {Form,Card, Button } from 'react-bootstrap';
import { Handle, Position, NodeToolbar, useNodeId,  useNodes} from 'reactflow';
import {  UilTrashAlt, UilPlusCircle} from '@iconscout/react-unicons';

import './ButtonInput.scss';

const ButtonInput = ({data, isConnectable}) => {

  const nodeId = useNodeId();
  const nodes = useNodes();

  const onButtonLabelChange = useCallback((e)=> {
    data.onChange(e.target.dataset.id,e.target.value);
  },[data]);

  const deleteNode = useCallback(()=> {
    data.onNodesDelete(nodeId);
  },[data, nodeId]);

  const addButton = useCallback((e)=> {

    const children = nodes.filter(node => node.parentNode === nodeId);
    const childrenCount = Number(children.length) + 1;

    data.addChildNode(e, nodeId, childrenCount);
  },[ data, nodeId, nodes]);

  const disableButtonAdd = useMemo(() => {
    const parents = nodes.filter(node => node.parentNode === nodeId);
    if(parents.length === 3) return true;
    
    },[nodes, nodeId]
  );

  const cardHeight = useMemo(() => {
    const parents = nodes.filter(node => node.parentNode === nodeId);
    return 14 + parents.length*6;
    
    },[nodes, nodeId]
  );

  const disableDleteParentNode = useMemo(() => {
    const parents = nodes.filter(node => node.parentNode === nodeId);
    if(parents.length > 0) return true;
    
    },[nodes, nodeId]
  );

  return (
    <div className="button-input-node">
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}>
        <Button 
          variant="outline-danger" 
          onClick={deleteNode}
          disabled={disableDleteParentNode}
        >
          <UilTrashAlt size="16"/>
        </Button>
      </NodeToolbar>
    <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
    <div>
    <Card style={{ width: '20rem', height: `${cardHeight}rem` }} className="px-4 py-3">
      <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
        <Form.Label>Caption :</Form.Label>
            <Form.Control
                placeholder='Type the caption'
                aria-label="Caption"
                aria-describedby="basic-addon1"
                onChange={onButtonLabelChange}
                value={data.value}
                type='text'
                data-id={nodeId}
                as="textarea" 
                rows={3}
            />
      </Form.Group>
      <Button 
          variant="outline-primary" 
          onClick={addButton}
          disabled={disableButtonAdd}
        >
          <UilPlusCircle size="14"/>
          <span className='add-button-text'>Add Button</span>
        </Button>
    </Card>
    </div>
    </div>
  )
}

export default ButtonInput;