import React, {useCallback, useState} from "react";
import PhoneInputContent from "./PhoneInputContent";
import {Card, Button} from 'react-bootstrap';
import { Handle, Position, NodeToolbar, useNodeId} from 'reactflow';
import {  UilTrashAlt } from '@iconscout/react-unicons';

const NumberInput = ({data, isConnectable}) => {

  const [userInput, setUserInput] = useState('');
  const nodeId = useNodeId();

  const onUserInputChange = useCallback((e)=> {
    data.onChange(e.target.dataset.id,e.target.value, data.valueSub);
    setUserInput(e.target.value);
  },[setUserInput, data]);

  const deleteNode = useCallback(()=> {
    data.onNodesDelete(nodeId);
  },[data, nodeId]);

  const onButtonLabelChange = useCallback((e)=> {
    data.onChange(e.target.dataset.id,data.value, e.target.value);
  },[ data]);

  const onRetryMessageChange = useCallback((e)=> {
    data.onChange(e.target.dataset.id,data.value,data.valueSub, e.target.value);
  },[data]);

  return (
    <div className="phone-input-node">
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}>
        <Button 
          variant="outline-danger" 
          onClick={deleteNode}
        >
          <UilTrashAlt size="16"/>
        </Button>
      </NodeToolbar>
    <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
    <div>
    <Card className="px-4 py-3">
      <PhoneInputContent
        label="Phone:"
        placeHolder='Type a phone number..'
        defaultValue={userInput}
        onChange={onUserInputChange}
        value={userInput}
        type='phone'
      />
      <PhoneInputContent
        label="Button Label:"
        defaultValue={data?.valueSub || 'Enter'}
        placeHolder='Send'
        onChange={onButtonLabelChange}
        value={data?.valueSub || 'Enter'}
        type='text'
      />
       <PhoneInputContent
        label="Retry Message:"
        defaultValue="This phone number doesn't seem to be valid. Can you type it again?"
        placeHolder="This phone number doesn't seem to be valid. Can you type it again?"
        onChange={onRetryMessageChange}
        value={data?.valueRetryMessage || "This phone number doesn't seem to be valid. Can you type it again?"}
        type='text'
      />
    </Card>
    </div>
    <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </div>
  )
};

export default NumberInput;
