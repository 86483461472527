import React from 'react';
import { 
  UilCalendarAlt, 
  UilText, 
  UilAt, 
  UilPhone, 
  UilListOlAlt, 
  UilGlobe,
  UilCheckCircle,
  UilImageV
} from '@iconscout/react-unicons';
import {Card} from 'react-bootstrap';
import './ToolKit.scss';

const iconMap = {
    'text' : <UilText size='25'/>,
    'date': <UilCalendarAlt />,
    'phone': <UilPhone />,
    'number': <UilListOlAlt/>,
    'button': <UilCheckCircle/>,
    'web' : <UilGlobe/>,
    'email': <UilAt /> ,
    'image': <UilImageV/>
}

const ToolKitComponent = ({name, type, className}) => {
  return (
    <Card 
        className='tool-kit-component pt-1 px-2' 
        style={{ width: '10rem' }}
    >
      <div className={`d-flex justify-content-start px-2 pt-1 tool-kit-content ${className}`}>
        <div className='pt-1'>
          {iconMap[type]}
        </div>
        <p className='pt-2 px-2'>{name}</p>
      </div>
    </Card>
  )
}

export default ToolKitComponent;