import React, {useCallback, useState} from "react";
import NumberInputContent from "./NumberInputContent";
import {Card,Button} from 'react-bootstrap';
import { Handle, Position, NodeToolbar } from 'reactflow';
import {  UilTrashAlt } from '@iconscout/react-unicons';

const NumberInput = ({data, isConnectable}) => {

  const [userInput, setUserInput] = useState('');
 // const [buttonLabel, setButtonLabel] = useState('');

  const onUserInputChange = useCallback((e)=> {
    setUserInput(e.target.value);
  },[setUserInput]);

  // const onButtonLabelChange = useCallback((e)=> {
  //   setButtonLabel(e.target.value);
  // },[setButtonLabel]);

  return (
    <div className="number-input-node">
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}>
        <Button 
          variant="outline-danger" 
          onClick={()=>{}}
        >
          <UilTrashAlt size="16"/>
        </Button>
      </NodeToolbar>
    <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
    <div>
    <Card className="px-4 pt-4">
      <NumberInputContent
        label="Number:"
        placeHolder='Type a number..'
        defaultValue={userInput}
        onChange={onUserInputChange}
        value={userInput}
        type='number'
      />
      {/* <NumberInputContent
        label="Button Label:"
        defaultValue={buttonLabel}
        placeHolder='Send'
        onChange={onButtonLabelChange}
        value={buttonLabel}
        type='text'
      /> */}
    </Card>
    </div>
    <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </div>
  )
};

export default NumberInput;
