import React from "react";
import {Form} from 'react-bootstrap';
import {useNodeId} from 'reactflow';

const EmailInputContent = ({
    type,
    defaultValue,
    label,
    placeholder,
    autoComplete,
    autoFocus,
    onChange,
    onFocus,
    onKeyUp,
    value
  })=> {

    const nodeId = useNodeId();

  return (
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                placeholder={placeholder}
                aria-label="Answer"
                aria-describedby="basic-addon1"
                onChange={onChange}
                value={value}
               // ref={inputRef}
                type={type}
                autoFocus={autoFocus}
                onFocus={onFocus}
                onKeyUp={onKeyUp}
                //onBlur={updateCarretPosition}
                autoComplete={autoComplete}
                data-id={nodeId}
            />
      </Form.Group>
  )
};

export default EmailInputContent;
