const statusColorMap = {
    'LIVE' : {
        color: 'success',
        text: 'Live'
    },
    'DRAFT': {
        color: 'warning',
        text: 'Draft'
    },
    'INACTIVE':{
        color: 'danger',
        text: 'Inactive'
    }
}

export {statusColorMap};