import React , {useCallback, useContext} from "react";
import {Container,Dropdown,DropdownButton, ButtonGroup} from 'react-bootstrap';
import Constants  from "../Constants";
import { UilUserCircle, UilSignout} from '@iconscout/react-unicons';
import { useNavigate } from "react-router-dom";
import { KeycloakContext } from '../Contexts/keycloakAuthServiceContext';
import {ControlHubIcon} from "../Components/utilComponents";

import './BaseLayout.scss';

const BaseLayout = ({ bottom}) => {

  const navigate = useNavigate();

    const { logout } = useContext(KeycloakContext);

  const handleSignOut = useCallback(() => {
    logout();
  },[logout]);

  const onNavigateToControlHub = useCallback(()=>{
    window.open(Constants.CONTROL_HUB_URL, '_blank');
  },[]);

  const onNavigateToLandingPage = useCallback(()=>{
    navigate(`/`);
  },[navigate]);

  return (
        <div className={`d-flex flex-column flex-fill w-100`}>
          <div className="top-layout">
            <div className='flow-list-view-top'>
              <Container className='d-flex justify-content-between align-items-center'>
                <div 
                  onClick={onNavigateToLandingPage}
                  className="flowy-home"
                >
                  {/* <UilEstate className='flowy-navigator' size='30'/> */}
                  <div> 
                    <h1 className='flowy-text'>
                      Flowy
                    </h1>
                    <h5 className='flowy-sub-text'>
                      Flow Bot Creator
                    </h5>
                  </div>
                </div>
               
                <div  className="d-flex flex-row control-hub">
                  <DropdownButton
                    data-testid="navigate-dropdown"
                    bsPrefix="dropdown-btn single-dropdown-toggle"
                    title={
                      <UilUserCircle size='30' className='profile-icon'/>
                      }
                    as={ButtonGroup}
                    size="lg"
                  >                             
                    <Dropdown.Item>
                      <div 
                        className="d-flex flex-row control-hub"
                        onClick={onNavigateToControlHub}
                      >
                       
                          <ControlHubIcon/> 
                          <h6 className="control-hub-text"> Control Hub</h6>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div 
                        className="d-flex flex-row"
                        onClick={handleSignOut}
                      >
                        <UilSignout className='profile-icon'/>
                        <h6 className="logout-text">Logout</h6>
                      </div>                
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Container>
            </div>
          </div>
          <div className="bottom-layout">
            {bottom}
          </div>
        </div>
  );
};

export default BaseLayout;