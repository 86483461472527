import { fetchGet, fetchPost, fetchPut, fetchDelete, fetchPostMultipart} from "./CommonServiceUtils";
import Constants  from "../Constants";

const createFlow = (botId, payload) => {
    return fetchPost(`${Constants.BASE_URL}flowy/flow/${botId}`, payload);
}

const getFlow = (botId) => {
    return fetchGet(`${Constants.BASE_URL}flowy/flow/${botId}`);
}

const updateFlow = (flowId, payload) => {
    return fetchPut(`${Constants.BASE_URL}flowy/flow/${flowId}`, payload);
} 

const publishFlow = (flowId) => {
    return fetchPut(`${Constants.BASE_URL}flowy/publish/${flowId}`);
}

const unpublishFlow = (botId) => {
    return fetchDelete(`${Constants.BASE_URL}flowy/publish/${botId}`);
}

const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return fetchPostMultipart(`${Constants.BASE_URL}imageuploads`, formData);
};

export {getFlow, createFlow, updateFlow, publishFlow, unpublishFlow, uploadImage};
