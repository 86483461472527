import React, {useCallback} from 'react';
import {Form,Card, Button } from 'react-bootstrap';
import { Handle, Position, useNodeId,  NodeToolbar} from 'reactflow';
import {  UilTrashAlt} from '@iconscout/react-unicons';

import './ButtonInput.scss';

const ButtonSubNode = ({data, isConnectable}) => {

  const nodeId = useNodeId();

  const onButtonLabelChange = useCallback((e)=> {
    data.onChange(e.target.dataset.id,e.target.value);
  },[data]);

  const deleteNode = useCallback(()=> {
    data.onNodesDelete(nodeId);
  },[data, nodeId]);

  return (
    <div className="button-input-sub-node">
        <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}>
            <Button 
                variant="outline-danger" 
                onClick={deleteNode}
            >
                <UilTrashAlt size="16"/>
            </Button>
        </NodeToolbar>
        <div>
            <Card className="px-4 pt-4">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    {/* <Form.Label>
                        Button Label:
                    </Form.Label> */}
                    <Form.Control
                        placeholder='Type the button label'
                        aria-label="Answer"
                        aria-describedby="basic-addon1"
                        onChange={onButtonLabelChange}
                        value={data.value}
                        type='text'
                        data-id={nodeId}
                        maxLength="20"
                     />
           
                </Form.Group>
            </Card>
        </div>
        <Handle
            type="source"
            position={Position.Right}
            id="b"
            isConnectable={isConnectable}
        />
    </div>
  )
}

export default ButtonSubNode;