import React, {useCallback} from 'react';
import {Modal, Button} from 'react-bootstrap';
import { toast } from "react-toastify";
import {unpublishFlow} from '../../Services'

const UnpublishConfirmationModal = ({show, setShow,botId, getBotDetails}) => {

    const handleClose = useCallback(() => {
        setShow(false);
    },[setShow]);

    const onDeleteBotFlow = useCallback(async()=> {
        try{
            await unpublishFlow(botId);
            toast.success(
                'Successfully removed this flow from the published flows!'
              );
            handleClose();
            getBotDetails();

        }catch(e){
            console.error(`Failed to removed this flow from the published flows`);
            toast.error(
                'Failed to removed this flow from the published flows!'
              );
        }

    },[
        botId,
        handleClose,
        getBotDetails
    ]);
  return (
    <Modal 
        show={show} 
        onHide={handleClose}
        centered
        className='flow-modal'
    >
        <Modal.Header 
            className='py-4'
            closeButton>
            <Modal.Title>
                Unpublish Flow 
            </Modal.Title>
        </Modal.Header>
        <Modal.Body  className='py-4'>
            <p>Do you want to remove this flow from the published flows?</p>
        </Modal.Body>
        <Modal.Footer  className='pt-4'>
          <Button 
            variant="outline-secondary" 
            onClick={handleClose}
            >
            No, Cancel
          </Button>
          <Button 
            variant="outline-danger" 
            onClick={onDeleteBotFlow}
            >
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default UnpublishConfirmationModal