import React from "react";
import { ToastContainer, Slide } from "react-toastify";
import {UserContextProvider,KeycloakContextProvider} from "./Contexts";
import BaseRoutes from "./BaseRoutes";
import "react-toastify/dist/ReactToastify.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'reactflow/dist/style.css';
import './LocalTheme.scss';

const App = () => {
   
    return (
        <div className="h-100">
            <KeycloakContextProvider>
                <UserContextProvider>
                    <ToastContainer
                        position="top-center"
                        transition={Slide}
                        theme="colored"
                        pauseOnFocusLoss={false}
                    />
                    <BaseRoutes/>
                </UserContextProvider>
            </KeycloakContextProvider>
        </div>
    );
};

export default App;
