import React, {useCallback} from 'react';
import {Form, Card,Button} from 'react-bootstrap';
import { Handle, Position, NodeToolbar, useNodeId} from 'reactflow';
import {  UilTrashAlt} from '@iconscout/react-unicons';

const TextBubble = ({data, isConnectable}) => {

  const nodeId = useNodeId();
  
  const onTextBubbleChange = useCallback((e)=> {
    data.onChange(e.target.dataset.id, e.target.value);
  },[data]);

  const deleteNode = useCallback(()=> {
    data.onNodesDelete(nodeId);
  },[data, nodeId]);

  return (
    <div className="text-bubble-node">
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}>
        <Button 
          variant="outline-danger" 
          onClick={deleteNode}
          data-id={nodeId}
        >
          <UilTrashAlt size="16"/>
        </Button>
      </NodeToolbar>
      <Handle 
        type="target" 
        position={Position.Top} 
        isConnectable={isConnectable} 
      />
      <div>
        <Card className="px-4 pt-3">
          <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
            <Form.Label>Text:</Form.Label>
              <Form.Control
                placeholder='Type a text'
                aria-label="Answer"
                aria-describedby="basic-addon1"
                onChange={onTextBubbleChange}
                value={data.value}
                type='text'
                data-id={nodeId}
                as="textarea" 
                rows={3}
              />
          </Form.Group>
        </Card>
    </div>
    <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
  </div>
  )
}

export default TextBubble;