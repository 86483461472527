import React, {useCallback, useState} from 'react';
import BaseLayout from '../../Layout/BaseLayout';
import {Form, Button, Container, Image, Row, Col} from 'react-bootstrap';
import {languages} from '../data';
import { toast } from "react-toastify";
import { UilPlus } from '@iconscout/react-unicons';
import { createFlowBot} from '../../Services';
import { useNavigate } from "react-router-dom";
import bot_create_imaget from '../../assets/images/bot_create_image.png';

import './BotCreateView.scss';

const BotCreateView = ({text = ' '}) => {

    const [botName, setBotName] = useState('');
    const [botLanguage, setBotLanguage] = useState('English');
    const navigate = useNavigate();

    const onSetBotName = useCallback((e) => {
        setBotName(e.target.value);
    },[setBotName]);

    const onSetBotLanguage = useCallback((e) => {
        setBotLanguage(e.target.value);
    },[setBotLanguage]);

    const onNavigateToFlowView = useCallback((botDetails)=> {
      navigate(`/flows/${botDetails._id}`,{ state:botDetails});

  },[navigate]);

    const onCreateBotFlow = useCallback(async()=> {
        try{
          const payload ={
            name: botName,
            status: "DRAFT",
            language: botLanguage 
          }
           const botDetails =  await createFlowBot(payload);
            setBotName('');
            setBotLanguage('');
            toast.success(
                'Successfully created a flow bot!'
              );
            onNavigateToFlowView(botDetails);
            
        }catch(e){
            console.error(`Failed to create a flow bot`);
            toast.error(
                'Failed to create a flow bot!'
              );
        }

    },[
        botLanguage,
        botName,
        setBotName,
        setBotLanguage,
        onNavigateToFlowView
    ]);

  return (
    <BaseLayout
      bottom={
        <div className='flow-list-view-bottom'>
        <Container className="bot-create-content">
          <div className='bot-create-top'>
            <Image 
              className="bot-image" 
              src={bot_create_imaget} 
              rounded 
            />
            <h3 className='bot-create-text'>
              {`Create Your${text}Flow Bot with Us!`}
            </h3>
          </div>
          <Row className="justify-content-center mt-3">
            <Col xs={7} md={5} lg={5} xl={5} xxl={5}>
              <Form>
                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                  <Form.Label className='label-text'>
                    Flow Bot Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Flow Bot Name"
                    value={botName}
                    onChange={onSetBotName}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group
                  className="mt-4"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className='label-text'>
                    Language
                  </Form.Label>
                  <Form.Select 
                    aria-label="Default select example" 
                    value={botLanguage}
                    onChange={onSetBotLanguage}>
                    {languages && languages.map(language=> 
                        <option value={language?.name} key={language?.name}>{language?.name}</option>
                        )
                    }
                    <option value="Other" key='Other'>Other</option>
                  </Form.Select>
                </Form.Group>
              </Form>
              <div className="bot-create-form">
                <Button 
                  variant='primary'
                  onClick={onCreateBotFlow}
                  className='white-text-button'
                  size="lg"
                >
                  <div className='my-2 mx-4'>
                    <UilPlus size="20"/> Create a Flow Bot
                  </div>
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        </div>
      }/>
  )
}

export default BotCreateView;