import React, {useState, useRef,useCallback, useMemo, useEffect, useContext} from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  Position,
  MarkerType
} from 'reactflow';
import ToolKitContainer from './toolKit/ToolKitContainer';
import {Button, Container, Dropdown,DropdownButton, ButtonGroup} from 'react-bootstrap';
import { UilAngleLeft, UilSave, UilUploadAlt, UilFileSlash, UilUserCircle, UilSignout } from '@iconscout/react-unicons';
import { useNavigate } from "react-router-dom";
import {TextBubble, ImageBubble} from '../flowEditor/editorComponents/bubbles';
import {
  TextInput, 
  PhoneInput, 
  NumberInput, 
  WebsiteInput, 
  ButtonInput, 
  EmailInput, 
  DateInput,
  ButtonSubNode
} from '../flowEditor/editorComponents/inputs';
import Constants  from "../../Constants";
import logo from '../../assets/images/logo.png';
import StartNode from '../flowEditor/editorComponents/startNode/StartNode';
import PreviewPanel from './previewPanel/PreviewPanel';
import { NODE_TYPES } from '../data';
import { toast } from "react-toastify";
import {createFlow, getFlow, updateFlow, publishFlow, getFlowBot} from "../../Services";
import { useParams, useLocation } from 'react-router-dom';
import UnpublishConfirmationModal from './UnpublishConfirmModal';
import { KeycloakContext } from '../../Contexts/keycloakAuthServiceContext';
import './FlowEditor.scss';

const BotStatus = {
  LIVE: 'LIVE',
  DRAFT: 'DRAFT',
  INACTIVE: 'INACTIVE'
}

let id = 0;
const getId = (type) => `${type}_${id++}`;


const initialNodes = [
  { id: 'node-1', type: 'START', position: { x: 0, y: 0}, data: { value: 'Start' } },
];

const FlowEditorContainer = () => {

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [flowNodes, setFlowNodes] = useState([]);
  const [flowDetails, setFlowDetails] = useState({flow:{}});
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [botDetails, setBotDetails] = useState({});

  const { logout } = useContext(KeycloakContext);

  const handleSignOut = useCallback(() => {
    logout();
  },[logout]);

  const nodeTypes = useMemo(() => ({ 
    TEXT_BUBBLE: TextBubble,
    TEXT_INPUT: TextInput,
    NUMBER_INPUT: NumberInput,
    WEB_INPUT: WebsiteInput,
    EMAIL_INPUT: EmailInput,
    DATE_INPUT: DateInput,
    PHONE_INPUT: PhoneInput,
    BUTTON_INPUT: ButtonInput,
    START: StartNode,
    BUTTON_INPUT_SUB_NODE: ButtonSubNode,
    IMAGE_BUBBLE: ImageBubble
  }), []);

  const onNavigateToFlowListView = useCallback(()=> {
    navigate(`/`);

  },[navigate]);

  const onNavigateToControlHub = useCallback(()=>{
    window.open(Constants.CONTROL_HUB_URL, '_blank');
  },[]);

  const onNavigateToPublishView = useCallback((url)=> {
    const details = {
      name: location?.state?.name,
      url : url
    }
    navigate(`/flows/${id}/publish`,{ state:details });

  },[navigate, id, location?.state?.name]);

  const reStructureNodes = useCallback(()=> {
    
    const newNodes = nodes.map(node =>{

      const newNode = {
        nodeId: node.id,
        data: node.data,
        type: node.type,
        parentNode: node.parentNode,
        sourceNodeId: '',
        targetNodeIds: [],
        //children: []
      }

      edges.forEach(edge => {
        if (node.id === edge.source) {
          newNode.targetNodeIds.push(edge.target);
        }
        if (node.id === edge.target) {
          newNode.sourceNodeId = edge.source;
        }
      });
    
      return newNode;
    });

    setFlowNodes(newNodes);

  },[nodes, edges, setFlowNodes]);

  const onConnect = useCallback((params) => {

    params.type = 'step';
    params.markerEnd = {
      type: MarkerType.ArrowClosed
    };
    setEdges((eds) => addEdge(params, eds));

  },[
    setEdges
  ]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onChange = useCallback((
    id, 
    value, 
    valueSub, 
    valueRetryMessage,
    userInputValue,
    // isSelectedFromButtons,
    // isSubmitText
    imageUrl,
    imageBlob
    )=> {
    setNodes((nds) =>
        nds.map((node) => {

          if (node.id !== id) {
            return node;
          }

          return {
            ...node,
            data: {
              ...node.data,
              value,
              valueSub,
              userInputValue,
              valueRetryMessage,
              // isSelectedFromButtons,
              // isSubmitText
              imageUrl,
              imageBlob
            },
          };
        })
      );

  },[setNodes]);

  const deleteNodeById = useCallback((id) => {
    setNodes((nds) => nds.filter((node) => node.id !== id));
  },[setNodes]);

  const addChildNode = useCallback((e, parentId, childrenCount=1)=> {

    let yPosition = 65 * Number(childrenCount);

    const position = {
      x: 18,
      y: 115 + yPosition
    };

    const newNode = {
      id: getId(NODE_TYPES.BUTTON_INPUT_SUB_NODE),
      type: NODE_TYPES.BUTTON_INPUT_SUB_NODE,
      position,
      data: { 
        label: `${NODE_TYPES.BUTTON_INPUT_SUB_NODE} node`, 
        toolbarPosition: Position.Right, 
        value:'',
        valueSub:'',
        userInputValue:'', 
        // isSelectedFromButtons: false,
        // isSubmitText:false,
        onChange: onChange, 
        onNodesDelete:deleteNodeById
      },
      deletable: true,
      parentNode:parentId,
      extent: 'parent'
    };

    setNodes((nds) => nds.concat(newNode));

  },[setNodes, onChange,deleteNodeById]);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      let newNode = {};

      if(type === NODE_TYPES.BUTTON_INPUT){
        newNode = {
          id: getId(type),
          type,
          position,
          data: { 
            label: `${type} node`, 
            toolbarPosition: Position.Right, 
            value:'', 
            valueSub:'',
            userInputValue:'', 
            // isSelectedFromButtons: false,
            // isSubmitText:false,
            onChange: onChange, 
            onNodesDelete:deleteNodeById, 
            addChildNode: addChildNode 
          },
          deletable: true,
          parentNode: ''
        };
      }else{
        newNode = {
          id: getId(type),
          type,
          position,
          data: { 
            label: `${type} node`, 
            toolbarPosition: Position.Right, 
            value:'', 
            imageUrl:'',
            imageBlob:'',
            onChange: onChange, 
            onNodesDelete:deleteNodeById, 
            addChildNode: addChildNode 
          },
          deletable: true,
          parentNode: ''
        };

      }
      setNodes((nds) => nds.concat(newNode));
    },[reactFlowInstance,setNodes,onChange, deleteNodeById, addChildNode]);

  const onSaveFlow = useCallback(async()=> {

    const flow = {
      "nodes": JSON.stringify(nodes),
      "edges": JSON.stringify(edges)
    }   

    try{
      
      const res = flowDetails.flow.nodes ? await updateFlow(id, flow) : await createFlow(id,flow);

      toast.success(
        'Successfully saved the flow!'
      );

      if(res?.PublishedUrl){
        onNavigateToPublishView(res?.PublishedUrl);
      }
      
    }catch(e){
      console.error('Failed to save flow');
      toast.error(
        'Failed to save flow!'
      );
    }
  },[
    onNavigateToPublishView,
    nodes,
    edges,
    flowDetails,
    id
  ]);

  const onLoadFlow = useCallback(async()=> {

    try{
      
      const flowResponse = await getFlow(id);
     
      if(flowResponse?.flow.nodes){

        const nodesResponse = JSON.parse(flowResponse?.flow.nodes);
        const edgesResponse = JSON.parse(flowResponse?.flow.edges);
  
        nodesResponse.forEach(node => {
          node.data = {...node.data, onChange: onChange, 
            onNodesDelete:deleteNodeById, 
            addChildNode: addChildNode }
        });
  
        setFlowDetails(flowResponse);
        setNodes(nodesResponse);
        setEdges(edgesResponse);
      }
       
    }catch(e){
      console.error('Failed to load flow');
    }
  },[
    id,
    setNodes,
    setEdges,
    onChange,
    deleteNodeById,
    addChildNode,
    setFlowDetails
  ]);

  const publish = useCallback(async() => {
    try {
      const response = await publishFlow(id);
      toast.success('Successfully published a flow bot!');
     
      if(response?.PublishedUrl){
        onNavigateToPublishView(response?.PublishedUrl);
      }
      
    } catch (e) {
      console.error('Failed to publish flows');
      toast.error(
        'Failed to publish flows!'
      );
    }
  },[id, onNavigateToPublishView]);

  const onShowUnpublishModal= useCallback(async() => {
    setShowUnpublishModal(true);
  },[setShowUnpublishModal]);

  const getBotDetails = useCallback(async()=>{
    try{
        const res = await getFlowBot(id);
        setBotDetails(res);
    }catch(e){
        console.error('Failed to load bot details');
    }
    
  },[id, setBotDetails]);

  useEffect(()=>{
    if(id){
        getBotDetails();
    }
    //eslint-disable-next-line
  },[id]);

  useEffect(()=> {
    onLoadFlow();
    //eslint-disable-next-line
  },[]);

  useEffect(()=> {
    reStructureNodes();
    //eslint-disable-next-line
  },[nodes]);


  
  return (
        <>
         <div className="top-layout-editor">
            <div className='flow-list-view-top'>
              <Container className='d-flex justify-content-between align-items-center'>
                <div>
                  <h1 className='flowy-text'>
                    Flowy
                  </h1>
                  <h5 className='flowy-sub-text'>
                    {`${Constants.ORGANIZATION} Flow Bot Creator`}
                  </h5>
                </div>
                <div 
                  className="d-flex flex-row control-hub"
                >
                  <DropdownButton
                    data-testid="navigate-dropdown"
                    bsPrefix="dropdown-btn single-dropdown-toggle"
                    title={
                      <UilUserCircle size='30' className='profile-icon'/>
                      }
                    as={ButtonGroup}
                    size="lg"
                  >                             
                    <Dropdown.Item>
                      <div 
                        className="d-flex flex-row control-hub"
                        onClick={onNavigateToControlHub}
                      >
                        {/* <div> */}
                          <div className='logo-container'>
                            <img src={logo} alt="logo"/>
                          </div>
                          <h6 className="control-hub-text">Control Hub</h6>
                        {/* </div> */}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div 
                        className="d-flex flex-row"
                        onClick={handleSignOut}
                      >
                        <UilSignout className='profile-icon'/>
                        <h6 className="logout-text">Logout</h6>
                      </div>                
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Container>
            </div>
          </div>
          <div class="d-flex justify-content-between mx-5 px-5 flow-editor-top">
              <div className="d-flex justify-content-start">
                <div 
                  className='back-arrow'
                  onClick={onNavigateToFlowListView}
                >
                  <UilAngleLeft size="40"/>
                </div>
                <h5 className='flowy-text'>{location?.state?.name || botDetails?.name || 'Flow Bot'}</h5>
              </div>
              <div className="d-flex justify-content-start">
                <div className='top-buttons save'>
                  <Button 
                    variant="outline-primary" 
                    onClick={onSaveFlow}
                  >
                    <UilSave size="20"/> 
                    <span className='ml-1'>
                      {(location?.state?.status || botDetails?.status) === BotStatus.LIVE ?'Save & Publish' : 'Save'}
                    </span>
                  </Button>
                </div>
                <div className='top-buttons'>
                  {(location?.state?.status || botDetails?.status) === BotStatus.LIVE ?
                    <Button 
                     variant="secondary" 
                      onClick={onShowUnpublishModal}
                    >
                      <UilFileSlash size="20"/> 
                      <span className='ml-1'>Unpublish</span>
                    </Button>
                  :
                    <Button 
                      variant="primary" 
                      onClick={publish}
                      className='white-text-button'
                    >
                      <UilUploadAlt size="20"/> 
                      <span className='ml-1'>Publish</span>
                    </Button>
                  }
                </div>
              </div>
            </div>
          <div className="dndflow editor-bottom-layout">
            <ReactFlowProvider>
              <ToolKitContainer/>
              <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  onInit={setReactFlowInstance}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  nodeTypes={nodeTypes}
                  fitView
                  minZoom={1}
                  maxZoom={1}
                >
                  <Controls />
                  <Background/>
                  {/* <DownloadButton/> */}
                </ReactFlow>
              </div>
              { (flowNodes && flowNodes.length > 1) && <PreviewPanel flows={flowNodes}/>}
            </ReactFlowProvider>
          </div>
          {/* {showPublishedUrlModal && publishedUrl && <PublishedUrlModal 
            showPublishedUrlModal={showPublishedUrlModal}
            setShowPublishedUrlModal={setShowPublishedUrlModal}
            publishedUrl={publishedUrl}
            />
          } */}
          {
            showUnpublishModal && <UnpublishConfirmationModal 
            show={showUnpublishModal} 
            setShow={setShowUnpublishModal} 
            botId={id}
            getBotDetails={getBotDetails}
            />
          }
       </>
  )
}

export default FlowEditorContainer;
