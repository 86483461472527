import ShoutOUTAILogo from "./assets/images/logo.png";

const DefaultContext = {
    logo: ShoutOUTAILogo,
    keycloakConfig: `${process.env.PUBLIC_URL}/assets/config/${process.env.REACT_APP_KEYCLOAK_FILE_NAME}`,
    baseUrl: process.env.REACT_APP_API_BASE_URL || "",
};

let context;

switch (process.env.REACT_APP_BUILD_VARIANT) {
    default: {
        context = DefaultContext;
        break;
    }
}

export default context;
