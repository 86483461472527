import React, {Suspense, useContext} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
//import FlowListView from "./Components/flowListView/FlowListView";
import FlowEditorContainer from "./Components/flowEditor/FlowEditorContainer";
import FlowView from "./Components/flow/FlowView";
import LandingPage from './Components/landingView/LandingPage';
import BotCreateView from "./Components/botCreateView/BotCreateView";
import BotPublishView from "./Components/botPublishView/BotPublishView";
import { LoadingComponent } from "./Components/utils";
import ErrorBoundary from "./ErrorBoundary";
import {UserContext} from "./Contexts";


const BaseRoutes = () => {
  const {isAuth} = useContext(UserContext);

  const router = createBrowserRouter([
    // {
    //   path: "/",
    //   element: <FlowListView/>,
    //   errorElement:<ErrorBoundary />
    // },
    {
      path: "/",
      element: <LandingPage/>,
      errorElement:<ErrorBoundary />
    },
    {
      path: "/flow-create",
      element: <BotCreateView/>,
      errorElement:<ErrorBoundary />
    },
    {
      path: "/flows/:id/publish",
      element: <BotPublishView/>,
      errorElement:<ErrorBoundary />
    },
    {
      path: "/flows/:id",
      element: <FlowView/>,
      errorElement:<ErrorBoundary />
    },
    {
      path: "/flow-edior",
      element: <FlowEditorContainer/>,
      errorElement:<ErrorBoundary />
    }
  ]);

  return (
      <>
        {isAuth?(
              <Suspense fallback={<LoadingComponent />}>
                <RouterProvider router={router} />
              </Suspense>
          ):<LoadingComponent/>
        }
      </>
  );
};

export default BaseRoutes;