import React, { useCallback } from 'react';
import {Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ToolKitComponent from './ToolKitComponent';
import {NODE_TYPES} from '../../data';
import { UilInfoCircle } from '@iconscout/react-unicons'

import './ToolKit.scss';

const ToolKitContainer = () => {

  const onDragStart = useCallback((event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  },[]);

  const renderTooltipBubble = useCallback((props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div class="tooltip-text">
          <h3>Bot Message</h3>
          <p>
            Bot Message components represent messages that will be sent by the chatbot to the user. You can customize the content of the message, including text, buttons, and other elements. The message can be used to provide information, answer questions from the user. For example, you could use a Bot Message component to send a welcome message to users, or to provide instructions on how to use the chatbot.
          </p>
          <h6>Here are some tips for creating effective Bot Message components:</h6>
          <ul>
            <li>Keep the message short and to the point.</li>
            <li>Use clear and concise language.</li>
            <li>Use buttons to make it easy for users to take action.</li>
          </ul>
        </div>
    </Tooltip>
  ),[]);

  const renderTooltipInput = useCallback((props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div class="tooltip-text">
          <h3>User Inputs</h3>
          <p>
          User inputs components allow users to enter their responses or provide information. This is useful for collecting data from users, such as their personal information or their opinions.
          </p>
          <h6>Here are some examples of user inputs:</h6>
          <ul>
            <li>Text boxes: Users can type text into these boxes.</li>
            <li>Email: Users can type an email address into these boxes</li>
            <li>Phone Number:  Users can type a phone number into these boxes</li>
          </ul>
        </div>
    </Tooltip>
  ),[]);

  return (
    <Card 
        className='tool-kit-container py-1 px-2' 
        style={{ width: '14rem' }}
    >
        <Card.Body>
          <div className='tool-sections mb-5'>
            <div className='d-flex justify-content-between mb-4 mt-3'>
              <h4 className="">Bot Messages</h4>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipBubble}
              >
                <div className='info-icon'>
                  <UilInfoCircle size={16}/>
                </div>
              </OverlayTrigger>
            </div>
            
            <div className='mb-4' onDragStart={(event) => onDragStart(event, NODE_TYPES?.TEXT_BUBBLE)} draggable>
              <ToolKitComponent name='Text' type='text' className='bubble-item'/>
            </div>
            <div className='mb-4' onDragStart={(event) => onDragStart(event, NODE_TYPES?.BUTTON_INPUT)} draggable>
                <ToolKitComponent name='Button' type='button' className='bubble-item'/>
            </div>
            <div onDragStart={(event) => onDragStart(event, NODE_TYPES?.IMAGE_BUBBLE)} draggable>
                <ToolKitComponent name='Image' type='image' className='bubble-item'/>
            </div>
          </div>
          <div className='tool-sections'>
          <div className='d-flex justify-content-between mb-4 mt-3'>
              <h4 className="">User Inputs</h4>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipInput}
              >
                <div className='info-icon'>
                  <UilInfoCircle size={16}/>
                </div>
              </OverlayTrigger>
            </div>
            {/* <div className="d-flex justify-content-between mb-3"> */}
              <div className='mb-4 bubble-item' onDragStart={(event) => onDragStart(event, NODE_TYPES?.TEXT_INPUT)} draggable>
                <ToolKitComponent name='Text' type='text' className='input-item'/>
              </div>
              {/* <div className='mb-4' onDragStart={(event) => onDragStart(event, NODE_TYPES?.NUMBER_INPUT)} draggable>
                <ToolKitComponent name='Number' type='number' className='input-item'/>
              </div> */}
            {/* </div> */}
            {/* <div className="d-flex justify-content-between mb-3"> */}
              <div className='mb-4' onDragStart={(event) => onDragStart(event, NODE_TYPES?.EMAIL_INPUT)} draggable>
                <ToolKitComponent name='Email' type='email' className='input-item'/>
              </div>
              {/* <div className='mb-4' onDragStart={(event) => onDragStart(event, NODE_TYPES?.WEB_INPUT)} draggable>
                <ToolKitComponent name='Web' type='web' className='input-item'/>
              </div> */}
            {/* </div> */}
            {/* <div className="d-flex justify-content-between mb-3"> */}
              {/* <div className='mb-4' onDragStart={(event) => onDragStart(event, NODE_TYPES?.DATE_INPUT)} draggable>
                <ToolKitComponent name='Date' type='date' className='input-item'/>
              </div> */}
              <div className='mb-4' onDragStart={(event) => onDragStart(event, NODE_TYPES?.PHONE_INPUT)} draggable>
                <ToolKitComponent name='Phone' type='phone' className='input-item'/>
              </div>
            {/* </div> */}
            {/* <div className="d-flex justify-content-between"> */}
              {/* <div onDragStart={(event) => onDragStart(event, NODE_TYPES?.BUTTON_INPUT)} draggable>
                <ToolKitComponent name='Button' type='button' className='input-item'/>
              </div> */}
            {/* </div> */}
          </div>
        </Card.Body>
      </Card>
  )
}

export default ToolKitContainer;