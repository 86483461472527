import React, {useCallback, useState, useEffect} from 'react';
import {Form, Modal, Button} from 'react-bootstrap';
import {languages, statusArray} from '../../data';
import { toast } from "react-toastify";
import { createFlowBot, updateFlowBot } from '../../../Services';

const FlowDetailsModel = ({show, setShow,title, buttonName, isEdit,botDetails, getBotFlows}) => {

    const [botName, setBotName] = useState('');
    const [botLanguage, setBotLanguage] = useState('English');
    const [botStatus, setBotStatus] = useState('');

    const handleClose = useCallback(() => {
        setShow(false);
    },[setShow]);

    const onSetBotName = useCallback((e) => {
        setBotName(e.target.value);
    },[setBotName]);

    const onSetBotLanguage = useCallback((e) => {
        setBotLanguage(e.target.value);
    },[setBotLanguage]);

    const onSetBotStatus = useCallback((e) => {
      setBotStatus(e.target.value);
  },[setBotStatus]);

    const onCreateBotFlow = useCallback(async()=> {
        try{
          const payload ={
            name: botName,
            status: "DRAFT",
            language: botLanguage 
          }
            await createFlowBot(payload);
            await getBotFlows();
            setBotName('');
            setBotLanguage('');
            toast.success(
                'Successfully created a flow bot!'
              );
            handleClose();

        }catch(e){
            console.error(`Failed to create a flow bot`);
            toast.error(
                'Failed to create a flow bot!'
              );
        }

    },[
        botLanguage,
        botName,
        setBotName,
        setBotLanguage,
        handleClose,
        getBotFlows
    ]);

    const onEditBotFlow = useCallback(async()=> {
        try{
            const payload ={
              name: botName,
              status: botStatus,
              language: botLanguage
            }
            await updateFlowBot(payload, botDetails._id);
            await getBotFlows();
            setBotName('');
            setBotLanguage('');
            setBotStatus('');
            toast.success(
                'Successfully updated the flow bot!'
              );
            handleClose();
        }catch(e){
            console.error(`Failed to edit bot`);
            toast.error(
                'Failed to update the flow bot!'
              );
        }

    },[
        botLanguage,
        botName,
        setBotName,
        setBotLanguage,
        handleClose,
        getBotFlows,
        botDetails,
        botStatus,
        setBotStatus
    ]);

    useEffect(()=> {

        if(isEdit && botDetails){
            setBotName(botDetails?.name);
            setBotLanguage(botDetails?.language);
            setBotStatus(botDetails?.status);
        }

    // eslint-disable-next-line
    },[isEdit, botDetails]);

  return (
    <Modal 
        show={show} 
        onHide={handleClose}
        centered
        className='flow-modal'
    >
        <Modal.Header 
            className='py-4'
            closeButton>
            <Modal.Title>
                {title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body  className='py-4'>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="My Flow Bot"
                value={botName}
                onChange={onSetBotName}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
                <Form.Label>Language</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    value={botLanguage}
                    onChange={onSetBotLanguage}>
                    {languages && languages.map(language=> 
                        <option value={language?.name} key={language?.name}>{language?.name}</option>
                        )
                    }
                    <option value="Other" key='Other'>Other</option>
                </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
                <Form.Label>Status</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    value={botStatus}
                    onChange={onSetBotStatus}>
                    {statusArray && statusArray.map(status=> 
                        <option value={status.name} key={status.name}>{status.displayName}</option>
                        )
                    }
                </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer  className='pt-4'>
          <Button 
            variant="outline-secondary" 
            onClick={handleClose}
            >
            Close
          </Button>
          <Button 
            variant="outline-primary" 
            onClick={isEdit? onEditBotFlow : onCreateBotFlow}
            >
            {buttonName}
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default FlowDetailsModel