import React from 'react';
import {Card } from 'react-bootstrap';
import { Handle, Position} from 'reactflow';

import './StartNode.scss';

const StartNode = ({data, isConnectable}) => {
 
  return (
    <div className="start-node">
    <Card style={{ width: '10rem' }} className="px-4 pt-4">
        <Card.Title className='pb-3 start-node-title'>Start</Card.Title>
    </Card>
    <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </div>
  )
}

export default StartNode;