import React, {useMemo, useCallback, useState} from 'react';
import {Button,InputGroup, Form, Image} from 'react-bootstrap';
import { NODE_TYPES } from '../../data';
import { UilRobot , UilUserCircle} from '@iconscout/react-unicons';
import validator from 'validator';
import { renderLinksFromString } from '../../utils/linkUtils';

import './PreviewPanel.scss';

const PreviewPanelNode = ({
  node, 
  index, 
  nodes,
  selectedButtonNodes,
  setSelectedButtonNodes,
  setParentButtonNodes,
  parentButtonNodes,
  setSubmittedUserInputs,
  submittedUserInputs,
  setPassedNodes,
  passedNodes
}) => {

  const [isIncorrectEmail, setIsIncorrectEmail] = useState(false);
  const [isIncorrectPhone, setIsIncorrectPhone] = useState(false);

  const onUserInputChange = useCallback((e)=> {
    node.data.onChange(e.target.dataset.id,node.data.value, node.data.valueSub,node.data.valueRetryMessage, e.target.value);
  },[node.data]);

  const subNodes = useMemo(()=> {
    return nodes.filter((item)=> item.parentNode === node.nodeId);
  },[node, nodes]);

  const onSelectButton = useCallback((e)=> {
    e.stopPropagation();
    e.preventDefault();
      node.data.onChange(
        e.target.dataset.id,
        e.target.value,
        null,
        null
        );
        setSelectedButtonNodes([...selectedButtonNodes, e.target.dataset.id]);
        setParentButtonNodes([...parentButtonNodes, node.nodeId]);
        setPassedNodes([...passedNodes, e.target.dataset.id]);
      
  },[
    node,
    selectedButtonNodes, 
    setSelectedButtonNodes,
    setParentButtonNodes,
    parentButtonNodes,
    setPassedNodes, passedNodes
  ]);

  const onSubmitUserInput = useCallback((e)=> {
    e.stopPropagation();
    e.preventDefault();

    let isValid = true;

    switch(node.type){
      case NODE_TYPES.PHONE_INPUT : 
        isValid = validator.isMobilePhone(node.data?.userInputValue);
        isValid ? setIsIncorrectPhone(false) : setIsIncorrectPhone(true);
      break;
      case NODE_TYPES.EMAIL_INPUT : 
        isValid = validator.isEmail(node.data.userInputValue);
        isValid ? setIsIncorrectEmail(false) : setIsIncorrectEmail(true);
      break;
      default: 
        isValid = true;
    }

    if (isValid) {
      node.data.onChange(
        e.target.dataset.id,
        node.data.value, 
        node.data.valueSub, 
        node.data.valueRetryMessage,
        node.data.userInputValue
        );
        setSubmittedUserInputs([...submittedUserInputs, e.target.dataset.id]);
        setPassedNodes([...passedNodes, e.target.dataset.id]);
   }
    
  },[
    node.data, 
    setSubmittedUserInputs, 
    submittedUserInputs, 
    setPassedNodes, 
    passedNodes,
    node.type,
    setIsIncorrectEmail,
    setIsIncorrectPhone
  ]);

  const displayNode = useMemo(() => {

    switch(node.type){
       case NODE_TYPES.TEXT_BUBBLE : {
           return (
            <div className='d-flex justify-content-start'>
                <div className='robot-icon'>
                    <UilRobot/>
                </div>
                <div key={index} className='bot-message'>
                    <div>{renderLinksFromString(node.data.value)}</div>
                </div>
            </div> 
           );
       }
       case NODE_TYPES.IMAGE_BUBBLE : {
        return (
         <div className='d-flex justify-content-start'>
             <div className='robot-icon'>
                 <UilRobot/>
             </div>
             <div key={index} className='bot-message'>
              <Image src={node.data?.imageUrl} rounded fluid/>
             </div>
         </div> 
        );
    }
       case NODE_TYPES.BUTTON_INPUT : {
           return (
            <>
              <div className='d-flex justify-content-start'>
              <div className='robot-icon'>
                  <UilRobot/>
                </div>
                <div key={index} className='bot-message'>
                  {node.data.value}
                </div>
                
              </div> 
                <div className='d-flex justify-content-start user-button-group'>
                  {subNodes?.length > 0 && subNodes.map((subNode, index) => (  
                    <Button 
                      variant={selectedButtonNodes.includes(subNode.nodeId) ? "outline-secondary": "outline-primary"}
                      onClick={onSelectButton}
                      className='mb-2 mx-1'
                      key={index}
                      value={subNode.data.value}
                      data-id={subNode.nodeId}
                      disabled={parentButtonNodes.includes(node.nodeId)}
                    >
                      {subNode.data.value}
                    </Button>
                  ))
                  } 
                </div>
                {subNodes?.length > 0 && subNodes.map((subNode, index) => (  
                  <>
                {
                  selectedButtonNodes.includes(subNode.nodeId) &&
                  <div className='d-flex justify-content-end'>
                    <div key={index} className='user-message'>
                      {subNode.data.value}
                    </div>
                    <div className='user-icon'>
                      <UilUserCircle/>
                    </div>
                  </div> 
                }
                </>
                ))
              }
            </>
           );
       }
       case NODE_TYPES.TEXT_INPUT : {
        return (
          <>
             <div className='d-flex justify-content-end'>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder={node.data.value || 'Text'}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={node.data.userInputValue}
                    data-id={node.nodeId}
                    onChange={onUserInputChange}
                    type='text'
                  />
                  <Button 
                    variant="outline-primary" 
                    id="button-addon2"
                    data-id={node.nodeId}
                    onClick={onSubmitUserInput}
                  >
                    {node.data.valueSub || 'Enter'}
                  </Button>
                </InputGroup>
                <div className='user-icon'>
                  <UilUserCircle/>
                </div>
            </div>
            {/* {
              node.data.isSubmitText &&
              <div className='d-flex justify-content-start'>
                <div className='robot-icon'>
                    <UilRobot/>
                </div>
                <div key={index} className='bot-message'>
                    {`You just entered ${node.data.userInputValue}`}
                </div>
            </div> 
            } */}
            </>
        );
      }
      case NODE_TYPES.PHONE_INPUT : {
        return (
          <>
          <div className='d-flex justify-content-end'>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder={node.data.value || 'Phone Number'}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={node.data.userInputValue}
                onChange={onUserInputChange}
                data-id={node.nodeId}
                type='text'
              />
              <Button 
                variant="outline-primary" 
                id="button-addon2"
                onClick={onSubmitUserInput}
                data-id={node.nodeId}
                >
                {node.data.valueSub || 'Enter'}
              </Button>
            </InputGroup>
            <div className='user-icon'>
              <UilUserCircle/>
            </div>
          </div>
          {
              isIncorrectPhone  &&
              <div className='d-flex justify-content-start'>
                <div className='robot-icon'>
                    <UilRobot/>
                </div>
                <>
                {
                  node.data?.valueRetryMessage ? 
                  <div key={index} className='bot-message'>
                      {`${node.data?.valueRetryMessage}`}
                  </div>
                :
                <div key={index} className='bot-message'>
                    This phone number doesn't seem to be valid. Can you type it again?
                </div>
                  
                }
                </> 
            </div> 
            }
          </>
        );
      }
      case NODE_TYPES.EMAIL_INPUT : {
        return (
          <>
          <div className='d-flex justify-content-end'>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder={node.data.value || 'Email'}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={node.data.userInputValue}
                onChange={onUserInputChange}
                data-id={node.nodeId}
                type='email'
              />
              <Button 
                variant="outline-primary" 
                id="button-addon2"
                onClick={onSubmitUserInput}
                data-id={node.nodeId}
              >
                {node.data.valueSub || 'Enter'}
              </Button>
            </InputGroup>
            <div className='user-icon'>
              <UilUserCircle/>
            </div>
          </div>
          {
              isIncorrectEmail && 
              <div className='d-flex justify-content-start'>
                <div className='robot-icon'>
                    <UilRobot/>
                </div>
                <>
                {
                  node.data?.valueRetryMessage?
                  <div key={index} className='bot-message'>
                    {`${node.data?.valueRetryMessage}`}
                </div>
                :
                <div key={index} className='bot-message'>
                   This email doesn't seem to be valid. Can you type it again?
                </div>
                }
                </>
                
            </div> 
            }
          </>
        );
      }
       default : {
           return (
            <div className='d-flex justify-content-start'>
            <div className='robot-icon'>
                <UilRobot/>
            </div>
            <div key={index} className='bot-message'>
                {node?.data?.value}
            </div>
        </div> 
           );
       }
   }


  }, [
        node,
        index,
        onUserInputChange,
        subNodes,
        onSelectButton,
        onSubmitUserInput,
        parentButtonNodes,
        selectedButtonNodes,
        isIncorrectEmail,
        isIncorrectPhone
    ]);



  return (
    <>
      {displayNode}
    </>
  )
}

export default PreviewPanelNode;