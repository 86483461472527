const languages = [
    {
        name: 'Catalan'
    },
    {
        name: 'Danish'
    },
    {
        name: 'Dutch'
    },
    {
        name: 'English'
    },
    {
        name: 'Faroese'
    },
    {
        name: 'Finnish'
    },
    {
        name: 'Flemish'
    },
    {
        name: 'French'
    },
    {
        name: 'German'
    },
    {
        name: 'Greek'
    },
    {
        name: 'Icelandic'
    },
    {
        name: 'Italian'
    },
    {
        name: 'Norwegian'
    },
    {
        name: 'Portuguese'
    },
    {
        name: 'Russian'
    },
    {
        name: 'Spanish'
    },
    {
        name: 'Swedish'
    }, 
    {
        name: 'Sinhala'
    },
    {
        name: 'Tamil'
    }
]

export {languages}