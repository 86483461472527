import React from 'react';
import './LoadingComponent.scss';

const Loading = () => {
    return (
        <div className="loading-component" data-testid="loading-component">
            <div className="loader-content" data-testid="loading-content">
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                version="1.0" 
                width="64" 
                height="24" 
                viewBox="0 0 128 35" 
            >
                <g><circle  fill-opacity="1" cx="17.5" cy="17.5" r="17.5"/><animate attributeName="opacity" dur="2700ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.167;0.5;0.668;1" values="0.3;1;1;0.3;0.3"/></g>
                <g><circle  fill-opacity="1" cx="110.5" cy="17.5" r="17.5"/><animate attributeName="opacity" dur="2700ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.334;0.5;0.835;1" values="0.3;0.3;1;1;0.3"/></g>
                <g><circle  fill-opacity="1" cx="64" cy="17.5" r="17.5"/><animate attributeName="opacity" dur="2700ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.167;0.334;0.668;0.835;1" values="0.3;0.3;1;1;0.3;0.3"/></g>
            </svg>
            </div>
        </div>
    );
};
export default Loading;