import React, {useState, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import {Card,Badge, Row, Col, Button,Dropdown,DropdownButton, ButtonGroup} from 'react-bootstrap';
import {UilTrashAlt, UilEdit, UilEllipsisV, UilCopy } from '@iconscout/react-unicons';
import {statusColorMap} from '../data';
import moment from "moment";
import Avatar from 'react-avatar';
import FlowDetailsModel from '../flowListView/flowDetailsModel/FlowDetailsModel';
import DeleteConfirmationModal from '../flowListView/deleteConfirmationModal/DeleteConfirmationModal';
import { toast } from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const BotListCard = ({botDetails, getBotFlows}) => {

    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const navigate = useNavigate();

    const onShowFlowBotEditModal = useCallback((e)=> {
        e.stopPropagation();
        setShow(true);
    },[setShow]);

    const onShowFlowBotDeleteModal = useCallback((e)=> {
        e.stopPropagation();
        setShowDeleteModal(true);
    },[setShowDeleteModal]);

    const onNavigateToFlowView = useCallback(()=> {
        navigate(`/flows/${botDetails._id}`,{ state:botDetails});

    },[navigate, botDetails]);

    const onCopySuccess = useCallback(() => {
        toast.success("Copied to clipboard");
      }, []);

    return (
            <>
                <Card 
                    className='bot-list-card' 
                >
                    <Row className='mt-5 bot-card'>
                        <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1} onClick={onNavigateToFlowView}>
                            <div className='align-middle'>
                                <Avatar name={botDetails.name} size={40} round="20px" />
                            </div>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} onClick={onNavigateToFlowView}>
                            <h4 className='align-middle fw-bold'>
                                {botDetails.name}
                            </h4>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} onClick={onNavigateToFlowView}>
                            <h6 className='mb-3'>
                                Created On: {moment(botDetails.createdOn).format("LLL")}
                            </h6>
                            <h6>
                                Updated On: {moment(botDetails.updateddOn).format("LLL")}
                            </h6>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} onClick={onNavigateToFlowView}>
                            <h6>
                                Language: {botDetails.language}
                            </h6>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} onClick={onNavigateToFlowView}>
                            <h6>
                                Invocation Count: {botDetails?.invocationCount || 0}
                            </h6>
                        </Col>
                        <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1} onClick={onNavigateToFlowView}>
                            <Badge bg={statusColorMap[botDetails.status].color} className='align-middle'>
                                {statusColorMap[botDetails.status].text}
                            </Badge>
                        </Col>
                        <Col xxl={1} xl={1} lg={1} md={1} sm={2} xs={2}>
                            <div className='d-flex flex-row align-middle'>
                                <DropdownButton
                                    data-testid="points-dropdown"
                                    bsPrefix="dropdown-btn single-dropdown-toggle"
                                    title={
                                        <UilEllipsisV/>
                                        }
                                    as={ButtonGroup}
                                >                             
                                    <Dropdown.Item>
                                        <Button 
                                            variant="outline-primary" 
                                            onClick={onShowFlowBotEditModal}
                                        >
                                            <UilEdit size="20"/> Edit
                                        </Button>

                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <CopyToClipboard
                                            text={botDetails?.url}
                                            onCopy={onCopySuccess}
                                            className="cursor-pointer"
                                        >
                                        <Button 
                                            variant="outline-primary" 
                                        >
                                            <UilCopy size="20"/> Copy URL
                                        </Button> 
                                        </CopyToClipboard> 
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Button 
                                            variant="outline-danger" 
                                            onClick={onShowFlowBotDeleteModal}
                                        >
                                            <UilTrashAlt size="20"/> Delete
                                        </Button>  
                                    </Dropdown.Item>
                                </DropdownButton>
                            </div> 
                        </Col>
                    </Row>
                </Card>
                {show && 
                    <FlowDetailsModel 
                        show={show} 
                        setShow={setShow}
                        title='Edit Flow Bot'
                        buttonName='Edit' 
                        isEdit={true}
                        botDetails = {botDetails}
                        getBotFlows={getBotFlows}
                    />
                }
                {
                    showDeleteModal &&
                        <DeleteConfirmationModal
                            show={showDeleteModal} 
                            setShow={setShowDeleteModal}
                            botId = {botDetails._id}
                            loadBotFlows={getBotFlows}
                        />

                }
             </>
        )
};

export default BotListCard;