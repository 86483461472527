import React, {useReducer, useCallback, useEffect} from "react";
import Keycloak from "keycloak-js";
import { toast } from "react-toastify";
import AppContext from "../AppContext";


const KeycloakContext = React.createContext();

const initialState = {
    token: null,
    isAuth:false,
};

const KeycloakContextActions = {
    UPDATE_TOKEN: "setToken",
    SET_AUTH_INFO: "setAuthInfo"

};

const keycloakInstance = new Keycloak(AppContext.keycloakConfig);

const reducer = (state, action) => {
    switch (action.type) {
        case KeycloakContextActions.UPDATE_TOKEN: {
            return {
                ...state,
                token: action.token,
                isAuth: !!action.token,
            };
        }
        case KeycloakContextActions.SET_AUTH_INFO: {
            return {
                ...state,
                token: action?.info?.token,
                isAuth: !!action?.info?.isAuth,
            };
        }
        default: {
            return state;
        }
    }
};


const KeycloakContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const doLogin = useCallback( ()=>keycloakInstance.login() , []);

    const getAuthToken  = useCallback( ()=>keycloakInstance.token , []);

    const keycloakLogout  = useCallback( ()=>keycloakInstance.logout() , []);

    const updateToken  = useCallback(   (successCallback)=> keycloakInstance.updateToken(5).then(successCallback).catch(doLogin),[doLogin]);

    const updateAuthInfo  = useCallback(   ()=>dispatch({type: KeycloakContextActions.SET_AUTH_INFO, info:{
            token: keycloakInstance?.token,
            isAuth:keycloakInstance?.authenticated,
        }}), [dispatch]);

    const loadUserProfile  = useCallback(  () => keycloakInstance.loadUserInfo(), []);

    const hasRole  = useCallback(  (roles) => roles.some((role) => keycloakInstance.hasRealmRole(role)), []);

    const initKeycloak = useCallback(  (onAuthenticatedCallback) => {

        keycloakInstance.init({
            onLoad: 'login-required',
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            pkceMethod: 'S256',
        }).then((authenticated) => {
                if (authenticated) {
                    onAuthenticatedCallback();
                }else {
                    toast.error("user is not authenticated..!");
                    doLogin();
                }
            })
            .catch(console.error);
    }, [doLogin]);

    const logout = useCallback(() => {
        keycloakLogout();
    }, [keycloakLogout]);

    useEffect(() => {
        initKeycloak(updateAuthInfo);
        // eslint-disable-next-line
    }, []);

    const value = {
        ...state,
        getAuthToken,
        doLogin,
        updateToken,
        hasRole,
        keycloakLogout,
        loadUserProfile,
        updateAuthInfo,
        logout
    };

    return <KeycloakContext.Provider value={value}>{props.children}</KeycloakContext.Provider>
};

const KeycloakContextConsumer = KeycloakContext.Consumer;

export { KeycloakContext, KeycloakContextProvider, KeycloakContextConsumer };
