const statusArray = 
[
    {
        name: 'LIVE',
        displayName: 'Live'
    },
    {
        name: 'DRAFT',
        displayName: 'Draft'
    },
    {
        name: 'INACTIVE',
        displayName: 'Inactive'
    }
];

export {statusArray};