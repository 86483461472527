import React, {useCallback, useState} from "react";
import DateInputContent from "./DateInputContent";
import {Card,Button} from 'react-bootstrap';
import { Handle, Position, NodeToolbar } from 'reactflow';
import {  UilTrashAlt } from '@iconscout/react-unicons';

const DateInput = ({data, isConnectable}) => {

  const [userInput, setUserInput] = useState('');

  const onUserInputChange = useCallback((e)=> {
    setUserInput(e.target.value);
  },[setUserInput]);

  return (
    <div className="date-input-node">
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}>
        <Button 
          variant="outline-danger" 
          onClick={()=>{}}
        >
          <UilTrashAlt size="16"/>
        </Button>
      </NodeToolbar>
    <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
    <div>
    <Card className="px-4 pt-4">
      <DateInputContent
        label="Date:"
        placeHolder='Type a date..'
        defaultValue={userInput}
        onChange={onUserInputChange}
        value={userInput}
        type='date'
      />
    </Card>
    </div>
    <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </div>
  )
};

export default DateInput;
