import React, {useCallback, useState, useEffect} from 'react';
import {Form, Button, Container, InputGroup, Col, Row, Card, Image} from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BaseLayout from '../../Layout/BaseLayout';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { UilCopy, UilAngleLeft, UilInfoCircle } from '@iconscout/react-unicons';
import { toast } from "react-toastify";
import Constants from '../../Constants';
import helpdeskIcon from '../../assets/images/helpdesk-icon.png';
import {getFlowBot} from '../../Services/FlowBotService';

import './BotPublishView.scss';

const BotPublishView = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [showHelpdesk, setShowHelpdesk] = useState(false);
  const [botDetails, setBotDetails] = useState({});

  const onNavigateToFlowView = useCallback(()=> {
    navigate(`/flows/${id}`);
  }, [id, navigate]);

  const onNavigateToControlHub = useCallback(()=>{
    window.open(`${Constants.CONTROL_HUB_URL}helpdesk`, '_blank');
  },[]);

  const onCopySuccess = useCallback(() => {
    toast.success("Copied to clipboard");
  }, []);

  const onShowHelpdesk = useCallback(()=>{
    setShowHelpdesk(true);
  },[setShowHelpdesk]);

  const getBotDetails = useCallback(async()=>{
    try{
        const res = await getFlowBot(id);
        setBotDetails(res);
    }catch(e){
        console.error('Failed to load bot details');
    }
    
  },[id, setBotDetails]);

  useEffect(()=>{
    if(id){
        getBotDetails();
    }
    //eslint-disable-next-line
  },[id]);

  return (
    <BaseLayout
        bottom={
            <Container className=''>
                <div class="d-flex justify-content-between mr-5 pr-5 flow-publish-top">
                    <div className="d-flex justify-content-start">
                        <div 
                            className='back-arrow'
                            onClick={onNavigateToFlowView}
                        >
                            <UilAngleLeft size="40"/>
                        </div>
                        <h5 className='flowy-text'>{location?.state?.name || botDetails?.name || 'Flow Bot' }</h5>
                    </div>
                </div>
                <div className='mt-5'>
                    <h3 className='mb-5 fw-bold'>Flowy Bot Link</h3>
                    <CopyToClipboard
                        text={location?.state?.url }
                        onCopy={onCopySuccess}
                        className="cursor-pointer"
                    >
                        
                        <Row>
                            <Col xxl={5} xl={5} lg={5} md={5} sm={7} xs={8}>
                                <Form className="d-flex">
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder={location?.state?.url || 'Not Available'}
                                            className="me-2"
                                            aria-label="publishedUrl"
                                            value={location?.state?.url || 'Not Available'}
                                            disabled='true'
                                        />
                                        <Button variant="outline-primary" id="button-search">
                                            <UilCopy size="20"/>
                                        </Button>
                                    </InputGroup>
                                </Form>
                            </Col>
                         </Row>
                    </CopyToClipboard>
                </div>
                <div className='mt-5'>
                    <h3 className='mb-5 fw-bold'>Integrate Your Flowy Bot</h3>
                    <Card 
                        className='publish-card' 
                    >
                        <Row className='my-5 mx-2 helpdesk-card'>
                            <Col xxl={2} xl={2} lg={2} md={2} sm={3} xs={3} className='pr-3'>
                                <div >
                                    <Image src={helpdeskIcon} rounded fluid className='helpdesk-img'/>
                                </div>
                            </Col>
                            <Col xxl={9} xl={9} lg={9} md={9} sm={8} xs={8}>
                                <div>
                                    <h4 className='fw-bold mb-3'>Helpdesk</h4>
                                    <p className='helpdesk-text'>
                                        Seamlessly integrate Flowy Bot with Helpdesk accross various platforms, including your website, Messenger, Whatsapp, and Telegram. 
                                    </p>
                                    <p className='helpdesk-text'>
                                        Enhance customer support and engagement by harnessing the power of Flowybot's intelligent automation, now easily accessible through your preferred communication channels. 
                                    </p>
                                </div>
                            </Col>
                            <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                                <Button 
                                    variant="primary" 
                                    onClick={onShowHelpdesk}
                                    className='connect-btn white-text-button'
                                    disabled={showHelpdesk}
                                >
                                    Connect
                                </Button>
                            </Col>
                        </Row>
                        {showHelpdesk &&
                        <Row className='helpdesk-card mb-5'>
                            <Col xxl={2} xl={2} lg={2} md={2} sm={3} xs={3} className='pr-3'>
                            </Col>
                            <Col xxl={10} xl={10} lg={10} md={10} sm={9} xs={9}>
                                <div className='helpdesk-info'>
                                    <Row>
                                        <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1} className='pr-3'>
                                            <UilInfoCircle size="35" className='info-icon'/>
                                        </Col>
                                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                                            <p className='fs-5 fw-bold helpdesk-text'>
                                                To connect your Flow bot, first, create an inbox in Helpdesk for your preferred channel. Currently there are no inboxes set up. Head to helpdesk to get started. 
                                            </p>
                                        </Col>
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                            <div className='d-flex justify-content-end helpdesk-btn '>
                                                <Button 
                                                    variant="primary" 
                                                    onClick={onNavigateToControlHub}
                                                    className='white-text-button'
                                                >
                                                    Go to Helpdesk
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        }
                    </Card>
                </div>
            </Container>
        }
    />
  )
}

export default BotPublishView