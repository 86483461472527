import React from 'react';
import './Utils.scss';

const ControlHubIcon = () => {
  return (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        enable-background="new 0 0 24 24" 
        viewBox="0 0 24 24" 
        id="control"
        className="control-hub-icon"
    >
        <path  d="M18,21H6c-1.7,0-3-1.3-3-3v-6c0-0.8,0.3-1.6,0.9-2.1l6-6c0.2-0.2,0.4-0.4,0.6-0.5c0.5-0.3,1.1-0.1,1.4,0.4   c0.3,0.5,0.1,1.1-0.4,1.4c-0.1,0-0.1,0.1-0.2,0.2l-6,6C5.1,11.5,5,11.7,5,12v6c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1v-5   c0-0.6,0.4-1,1-1s1,0.4,1,1v5C21,19.7,19.7,21,18,21z" />
        <path  d="M20,6.5h-0.2c-0.1-0.2-0.2-0.4-0.3-0.7l0.1-0.1c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-0.1,0.1  c-0.2-0.1-0.4-0.2-0.7-0.3V4c0-0.6-0.4-1-1-1s-1,0.4-1,1v0.2c-0.2,0.1-0.4,0.2-0.7,0.3l-0.1-0.1c-0.4-0.4-1-0.4-1.4,0  c-0.4,0.4-0.4,1,0,1.4l0.1,0.1c-0.1,0.2-0.2,0.4-0.3,0.7H13c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h0.2c0.1,0.2,0.2,0.4,0.3,0.7l-0.1,0.1  c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l0.1-0.1c0.2,0.1,0.4,0.2,0.7,0.3V11c0,0.6,0.4,1,1,1s1-0.4,1-1v-0.2  c0.2-0.1,0.4-0.2,0.7-0.3l0.1,0.1c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-0.1-0.1c0.1-0.2,0.2-0.4,0.3-0.7H20c0.6,0,1-0.4,1-1  C21,6.9,20.6,6.5,20,6.5z M16.5,9C15.7,9,15,8.3,15,7.5S15.7,6,16.5,6S18,6.7,18,7.5S17.3,9,16.5,9z"  />
    </svg>
  )
}

export default ControlHubIcon;