import React, {useCallback} from 'react';
import {Form, Card,Button,Image} from 'react-bootstrap';
import { Handle, Position, NodeToolbar, useNodeId} from 'reactflow';
import {  UilTrashAlt} from '@iconscout/react-unicons';
import { uploadImage } from '../../../../../Services';

import './ImageBubble.scss';

const ImageBubble = ({data, isConnectable}) => {

  const nodeId = useNodeId();
  
  const onImageBubbleChange = useCallback(async(e)=> {

    const imageRes = await uploadImage(e.target.files[0]);
    await data.onChange(e.target.dataset.id,data.value, data.valueSub,data.userInputValue,imageRes?.url);
  },[data]);

  const deleteNode = useCallback(()=> {
    data.onNodesDelete(nodeId);
  },[data, nodeId]);

  return (
    <div className="image-bubble-node">
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}>
        <Button 
          variant="outline-danger" 
          onClick={deleteNode}
          data-id={nodeId}
        >
          <UilTrashAlt size="16"/>
        </Button>
      </NodeToolbar>
      <Handle 
        type="target" 
        position={Position.Top} 
        isConnectable={isConnectable} 
      />
      <div>
        <Card className="px-4 pt-3">
        {data?.imageUrl && <Image src={data?.imageUrl} rounded fluid className='image mb-3'/>}
          <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
            <Form.Label>Image / GIF:</Form.Label>
              <Form.Control
                placeholder='Upload image'
                aria-label="Image"
                aria-describedby="basic-addon1"
                onChange={onImageBubbleChange}
                type="file"
                data-id={nodeId}
              />
          </Form.Group>
        </Card>
    </div>
    <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
  </div>
  )
}

export default ImageBubble;