import React from "react";
import {Form, InputGroup} from 'react-bootstrap';

const NumberInputContent = ({
    type,
    defaultValue,
    label,
    placeholder,
    autoComplete,
    autoFocus,
    onChange,
    onFocus,
    onKeyUp,
    value
  })=> {

  return (
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        {label && <Form.Label>{label}</Form.Label>}
        <InputGroup className="mb-3">
            <Form.Control
                placeholder={placeholder}
                aria-label="Answer"
                aria-describedby="basic-addon1"
                onChange={onChange}
                value={value}
               // ref={inputRef}
                type={type}
                autoFocus={autoFocus}
                onFocus={onFocus}
                onKeyUp={onKeyUp}
                //onBlur={updateCarretPosition}
                autoComplete={autoComplete}
            />
        </InputGroup>
      </Form.Group>
  )
};

export default NumberInputContent;
