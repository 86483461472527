import React, {useCallback, useContext, useEffect, useReducer,} from "react";
import { toast } from "react-toastify";
import {KeycloakContext} from "./keycloakAuthServiceContext";

// This context is created assuming that we will need to keep some user data in this application to sync with the main dashboard 

const UserContext = React.createContext();

const UserContextActions = {
    SET_INIT_SYSTEM_ERROR_MESSAGE: "setInitSystemErrorMsg",
    SET_USER_CONFIG_LOADED: "setUserConfigLoaded",
    SET_LOGGED_USER: "setLoggedUser",
};

const initialState = {
    systemInitFailMessage: null,
    userConfigLoaded: false,
    loggedUser: null,
    isRunningSystemInit: false,
    isAuth : true
};

const reducer = (state, action) => {
    switch (action.type) {
        case UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE: {
            return {
                ...state,
                systemInitFailMessage: action.message,
                isRunningSystemInit: false,
            };
        }
        case UserContextActions.SET_LOGGED_USER: {
            return {
                ...state,
                loggedUser: action.userInfo,
            };
        }
        default:
            return state;
    }
};

const constantMock = window.fetch;
const UserContextProvider = (props) => {
    const {keycloakLogout, loadUserProfile, isAuth ,getAuthToken} = useContext(KeycloakContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    window.fetch = async function () {
        const body = arguments[1];
        if (body.headers["x-auth"]) {
            delete body.headers["x-auth"];
            body.headers["Authorization"] = `Bearer ${getAuthToken()}`;
        }
        const response = await constantMock.apply(this, arguments);

        if (response.status === 401) {
            keycloakLogout();
        }

        return response;
    };
  
    const loadUserInfo = useCallback(async () => {
        try {
            const userInfo = await loadUserProfile();
            dispatch({ type: UserContextActions.SET_LOGGED_USER, userInfo });
        } catch (e) {
            console.error(e);
            toast.error(
                <div>
                    Failed to load user profile!
                    <br />
                    {e.message
                        ? `Error: ${e.message}`
                        : "Please try again later."}
                </div>
            );
        }
    }, [loadUserProfile, dispatch]);

    useEffect(() => {
        (async () => {
            if (isAuth) {
                loadUserInfo();
            }
        })();
        // eslint-disable-next-line
    }, [isAuth]);


    const value = {
        ...state,
        username: state.loggedUser?.username,
        email: state.loggedUser?.email,
        keycloakLogout,
        isAuth
    };

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    );
};

const UserContextConsumer = UserContext.Consumer;

export {
    UserContext,
    UserContextProvider,
    UserContextConsumer,
    UserContextActions,
};
